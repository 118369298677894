import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import {
	FaChartLine,
	FaHourglassHalf,
	FaSearchLocation,
	FaTrafficLight,
	FaUserShield,
	FaSync,
	FaHistory
} from "react-icons/fa";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import OutboundLink from "src/components/mdx/OutboundLink/OutboundLink";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Log Status Monitor Report",
	subTitle: (
		<>
			<p>Keep track of what Googlebot is seeing your website.</p>
			<OutboundLink
				href="https://lookerstudio.google.com/u/0/reporting/1544b49a-135a-41ec-92e0-876bc48eed1c/"
				className={`button buttonSecondary`}
			>
				Explore anonymised report
			</OutboundLink>
		</>
	)
};

const alternatingContent = [
	{
		subTitle: "Video Walkthrough",
		content: (
			<React.Fragment>
				<p>Sometimes it&apos;s easier to watch a video.</p>
				<p>
					If you&apos;d like a short walkthrough of what this report
					can do we&apos;ve got one here.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<>
				<div className={`videoHolder`}>
					<iframe
						src="https://player.vimeo.com/video/916060512?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						title="piped out intro 3"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js%22%3E" />
			</>
		)
	}
];
const dashboardStrengths = [
	{
		title: "Which status codes is Googlebot seeing?",
		content: (
			<React.Fragment>
				<p>
					Log data from Googlebot is one of the most valuable insights
					you get into your sites technical performance.
				</p>
				<p>
					Is Googlebot finding 302s? Is it crawling those parametered
					localisation redirects? Did it spot your blog migration?
				</p>
				<p>We&apos;ll help you find and identify.</p>
			</React.Fragment>
		),
		icon: <FaSearchLocation />
	},
	{
		title: "Get the latest data",
		content: (
			<React.Fragment>
				<p>
					We load log data roughly every hour so if you&apos;re
					monitoring a migration, you&apos;ll get the data as soon as
					possible.
				</p>
			</React.Fragment>
		),
		icon: <FaHourglassHalf />
	},
	{
		title: "Drilldown into site segments",
		content: (
			<React.Fragment>
				<p>
					We&apos;ll break your site down into different segments, so
					you can easily drill into the different templates and page
					types where changes have happened.
				</p>
			</React.Fragment>
		),
		icon: <FaChartLine />
	},
	{
		title: "Prioritise by traffic as well as volume",
		content: (
			<React.Fragment>
				<p>
					Suppose you have a bug where multiple URLs temporarily
					return 302s. How do you know if it was an important URL?
				</p>
				<p>
					Show status codes by average traffic as well as number of
					requests to help you spot errors on important pages.
				</p>
			</React.Fragment>
		),
		icon: <FaTrafficLight />
	},
	{
		title: "Do it without PII data.",
		content: (
			<React.Fragment>
				<p>
					We work with your engineering team to ensure we don&apos;t
					process PII and make it easier to get past compliance.
				</p>
			</React.Fragment>
		),
		icon: <FaUserShield />
	},
	{
		title: "Automatic updates",
		content: (
			<React.Fragment>
				<p>
					Like all our dashboards the data is updated automatically
					every day.
				</p>
			</React.Fragment>
		),
		icon: <FaSync />
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images

	const title = "Log File Analysis Dashboard Template";
	const description =
		"Discover how Google is crawling your website with our log monitoring dashboard. Use segmentations to dig in and get all the information you need to debug crawling issues.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>

			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					noBotMargin={true}
				/>
			</section>
			<StripTitle title="Why use our dashboard?" />
			<StripTwoColumnIconSmall
				contentList={dashboardStrengths}
				backgroundGrey={false}
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query LogMonitorCheckerData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg3: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/business_logic.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 866) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
